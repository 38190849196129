import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Controller, Mousewheel, Pagination } from 'swiper/modules';

import Customuse from "../../../assets/images/icons/Customuse.jpg";
import kard from "../../../assets/images/icons/Kard.jpg";
import Bunch from "../../../assets/images/icons/Bunch.jpg";
import Luni from "../../../assets/images/icons/Luniapp.jpg";
import Screen from "../../../assets/images/icons/Screenshot.jpg";
import Yubo from "../../../assets/images/icons/Yubo.jpg";

import "swiper/css";
import "swiper/css/pagination";

// Import Swiper styles
import "swiper/css";

function slider() {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Thumbs, Controller, Mousewheel, Pagination]}
      loop={true}
      spaceBetween={10}
      navigation={true}
      grabCursor={true}
      mousewheel={true}
      pagination={{
        clickable: true,
      }}
    >
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={kard} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Kard</h3>
              <a
                href="https://twitter.com/Kard_App"
                target="_blank"
                rel="noreferrer"
              >
                @Kard_App
              </a>
              <p>
                We’ve partnered with Makata pretty much since day 1, they’ve
                always played a tremendous role in Kard’s success.<br></br>
                <br></br>
                <b>Scott Gordon</b>
                <br></br>
                <i>CEO of KARD</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={Customuse} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Customuse</h3>
              <a
                href="https://twitter.com/customuse3d?lang=es"
                target="_blank"
                rel="noreferrer"
              >
                @customuse3D
              </a>
              <p>
                Working with makata team brings me a ton of joy
                <br></br>
                <br></br>
                <b>Arthur Safaryan</b>
                <br></br>
                <i>CEO of Customuse</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={Bunch} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Bunch</h3>
              <a
                href="https://twitter.com/bunchverse"
                target="_blank"
                rel="noreferrer"
              >
                @bunchverse
              </a>
              <p>
                Great design partners! We’ve been working together for many
                years now!<br></br>
                <br></br>
                <b>Selcuk Atli</b>
                <br></br>
                <i>CEO of Bunch.live</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={Luni} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Luni</h3>
              <a
                href="https://twitter.com/Luni_app"
                target="_blank"
                rel="noreferrer"
              >
                @Luni_app
              </a>
              <p>
                Makata team is fast, creative, and always open to new
                challenges! Happy to have them by our side at Luni!<br></br>
                <br></br>
                <b>Adrien Miniatti</b>
                <br></br>
                <i>CEO of Luni</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={Screen} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Screenshot</h3>
              <a
                href="https://mobile.twitter.com/screenshotlabs"
                target="_blank"
                rel="noreferrer"
              >
                @ScreenshotLabs
              </a>
              <p>
                “Speed of execution, attentive to our feedbacks, undeniable
                quality of the deliverables”<br></br>
                <br></br>
                <b>Team Everai</b>
                <br></br>
                <i>Screenshot.co</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="customers-main">
          <div className="customers-imgs">
            <div>
              <img src={Yubo} alt="Kard-img" />
            </div>
          </div>
          <div className="customers-contents">
            <div className="customers-content">
              <h3>Yubo</h3>
              <a
                href="https://twitter.com/yubo_app"
                target="_blank"
                rel="noreferrer"
              >
                @yubo_app
              </a>
              <p>
                Makata has been a long time partner of Yubo! We did many great
                project together as feature videos, 3D and even lenses for an
                in-app use. Always happy to ask for their help!<br></br>
                <br></br>
                <b>Jeremie Aouate</b>
                <br></br>
                <i>Co-Founder & CPO at Yubo</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default slider;
