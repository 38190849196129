import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from './components/pages/Home/Home'
import About from './components/pages/About/About'
import Contact from './components/pages/Contact/ContactPage'
import Error404 from './components/pages/Error404/Error404'
import './scss/app.scss';


function App() {
  let location = useLocation()
  useEffect(()=>{
    window.scrollTo(0, 0)

  },[location.pathname])
  
  return (
    <div className="App">
      <Routes>
          <Route path='/' exact='true' element={<Home />} />
          <Route path='/about' exact='true' element={<About />} />
          <Route path='/contact' exact='true' element={<Contact />} />
          <Route path='*' element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
