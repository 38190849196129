import React from "react";
import plus from "../../../assets/images/icons/plusBlue.svg";
import close from "../../../assets/images/icons/close-modal.svg";
import imgConcept from '../../../assets/videos/gifs/MAKATA_WEB_SERVICES_CONCEPTS.gif';
import img3D from '../../../assets/videos/gifs/MAKATA_WEB_SERVICES_3DANIMATION.gif';
import imgDev from '../../../assets/videos/gifs/MAKATA_WEB_SERVICES_DEV.gif';
import imgDesing from '../../../assets/videos/gifs/MAKATA_WEB_SERVICES_APPDESIGN.gif';

import Flip from "react-reveal/Flip";

function ModalMobile() {
  return (
    <section className="modal-mobile">
      <Flip top>
        <div>
          <a href="#modal" className="btn blue-wave">
            Concept arts <img className="plus" src={plus} alt='imgModal'/>
          </a>
        </div>
      </Flip>
      <Flip top>
        <div>
          <a href="#modal1" className="btn blue-wave1">
            3D things <img className="plus" src={plus} alt='imgModal' />
          </a>
        </div>
      </Flip>
      <Flip top>
        <div>
          <a href="#modal2" className="btn blue-wave2">
            Design Apps <img className="plus" src={plus} alt='imgModal' />
          </a>
        </div>
      </Flip>
      <Flip top>
        <div>
          <a href="#modal3" className="btn blue-wave3">
            Dev Experiences <img className="plus" src={plus} alt='imgModal' />
          </a>
        </div>
      </Flip>
      <div id="modal">
        <div className="container">
          <div className="link">
            <a href="#main">
            <img src={close} alt='close-modal'/>
            </a>
          </div>
          <img className="img-proyect" src={imgConcept} alt="service-img" />
        </div>
      </div>
      <div id="modal1">
        <div className="container">
          <div className="link">
            <a href="#main">
            <img src={close} alt='close-modal'/>
            </a>
          </div>
          <img className="img-proyect" src={img3D} alt="service-img" />
        </div>
      </div>
      <div id="modal2">
        <div className="container">
          <div className="link">
            <a href="#main">
            <img src={close} alt='close-modal'/>
            </a>
          </div>
          <img className="img-proyect" src={imgDesing} alt="service-img" />
        </div>
      </div>
      <div id="modal3">
        <div className="container">
          <div className="link">
            <a href="#main">
            <img src={close} alt='close-modal'/>
            </a>
          </div>
          <img className="img-proyect" src={imgDev} alt="service-img" />
        </div>
      </div>
    </section>
  );
}

export default ModalMobile;
