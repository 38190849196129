import Header from '../../utils/Header/Header'
import twitter from '../../../assets/images/icons/twitter.svg'
import instagram from '../../../assets/images/icons/Instagram.svg'
import arrow from '../../../assets/images/icons/arrowDown.svg'
import twitterHover from "../../../assets/images/icons/tw_hover.png";
import instagramHover from "../../../assets/images/icons/ig_hover.png";
import emailjs from '@emailjs/browser';
import { useRef } from 'react'

import sendForm from '../../../assets/images/MKT_RADIO_POST.png'

import React from 'react';
import Bounce from 'react-reveal/Bounce';

function Contact() {
  
    const labelName = useRef()
    const textName = useRef()
    const inputName = useRef()

    const labelEmail = useRef()
    const textEmail = useRef()
    const inputEmail = useRef()

    const interestMain = useRef()
    const labelInterest = useRef()

    const textAreaInput = useRef()
    const textAreaAsterisk = useRef()
    const textAreaMain = useRef()

    const conceptArts = useRef()
    const modelling = useRef()
    const rigging = useRef()
    const animation = useRef()
    const conceptArtsLabel = useRef()
    const modellingLabel = useRef()
    const riggingLabel = useRef()
    const animationLabel = useRef()
    const contactContent = useRef()
    const whenLabel = useRef()
    let inputsInteres = [
        conceptArts,
        modelling,
        rigging,
        animation,
    ]
    let labelInteres = [
        conceptArtsLabel,
        modellingLabel,
        riggingLabel,
        animationLabel,
    ]

    const optionsEmpty = useRef()
    const formEmail = useRef()
    const submitForm = useRef()
    const checkedConcept = useRef()
    const checkedModelling = useRef()
    const checkedRigging = useRef()
    const checkedAnimations = useRef()
    let checkedinterests = [
        checkedConcept,
        checkedModelling,
        checkedRigging,
        checkedAnimations,
    ]

    const whereMain = useRef()
    const whereAsterisk = useRef()
    const yesterday = useRef()
    const nextWeek = useRef()
    const future = useRef()
    let inputsWhere = [
        yesterday,
        nextWeek,
        future
    ]
    const yesterdayLabel = useRef()
    const nextWeekLabel  = useRef()
    const futureLabel  = useRef()
    const mainWhen = useRef()

    const contentMains = useRef()
    const imgdown = useRef();
    const optionsMain = useRef()
    const optionsTime = useRef();
    let classShowOptions
    let classOptionsTime

    function checked(){
        let actived = []
        for (let i = 0; i < inputsInteres.length; i++) {
            if(inputsInteres[i].current.checked){
                actived.push(i)
                labelInteres[i].current.classList.add('label-selected')

            }else{
                labelInteres[i].current.classList.remove('label-selected')
            }
        }
        if (actived.length !== 0) {
            optionsEmpty.current.classList.add('hidden-placeholder')
        }else{
            optionsEmpty.current.classList.remove('hidden-placeholder')
        }
    }
    const sendEmail = (e) => {
        e.preventDefault();
        let errores = []
        let labelInteresActive = 0
        let inputsWhereActive = 0
        if (inputName.current.value === '') {
            errores.push('name')
        }
        if (inputEmail.current.value === '') {
            errores.push('email')
        }
        inputsInteres.forEach(input =>{
            if(input.current.checked){
                labelInteresActive += 1
            }
        })
        if (labelInteresActive === 0) {
            errores.push('interes')
        }

        inputsWhere.forEach(inputWhere =>{
            if(inputWhere.current.checked){
                inputsWhereActive += 1
            }
        })
        if (inputsWhereActive === 0) {
            errores.push('where')
        }
        if(textAreaInput.current.value === ''){
            errores.push('textArea')
        }
        if (errores.length === 0) {
            emailjs.sendForm('service_tksc3z2', 'template_uqotsts', formEmail.current, '6mrjbj3HeUKXml9uV')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            formEmail.current.classList.add('hidden-form')
            submitForm.current.classList.add('send-form')
        }else{
            errores.forEach( item => {
                if (item === 'name') {
                    
                    labelName.current.classList.add('asterisk-validation')
                    textName.current.classList.add('validation-input')
                }
                if (item === 'email') {
                    labelEmail.current.classList.add('asterisk-validation')
                    textEmail.current.classList.add('validation-input')
                }
                if (item === 'interes') {
                    labelInterest.current.classList.add('asterisk-validation')
                    interestMain.current.classList.add('validation-input')
                }
                if (item === 'where') {
                    whereAsterisk.current.classList.add('asterisk-validation')
                    whereMain.current.classList.add('validation-input')
                }
                if (item === 'textArea') {
                    textAreaAsterisk.current.classList.add('asterisk-validation')
                    textAreaMain.current.classList.add('validation-input-textArea')
                }
            })
        }
    };
    function sendOtherChat(){
        checkedinterests.forEach(checkedInput =>{
            checkedInput.current.classList.remove('show-checked')
        })
        inputsInteres.forEach(input =>{
            input.current.checked = false
        })
        labelInteres.forEach(label =>{
            label.current.classList.remove('label-selected')
        })
        formEmail.current.reset()
        optionsEmpty.current.classList.remove('hidden-placeholder')
        formEmail.current.classList.remove('hidden-form')
        submitForm.current.classList.remove('send-form')
        yesterday.current.checked = false
        nextWeek.current.checked = false
        future.current.checked = false
        yesterdayLabel.current.classList.remove('label-selected')
        nextWeekLabel.current.classList.remove('label-selected')
        futureLabel.current.classList.remove('label-selected')
        mainWhen.current.children[0].innerText = 'Select the time you need'
        mainWhen.current.children[0].classList.remove('select-when')
    }
    function showOptionValidation() {
        classShowOptions = document.getElementsByClassName('showOptions')
        classOptionsTime = document.getElementsByClassName('showOptions')
    }
    document.addEventListener('scroll', ()=>{
        showOptionValidation()
        if(classShowOptions.length > 0 || classOptionsTime.length > 0){
            optionsMain.current.classList.remove('showOptions')
            optionsTime.current.classList.remove('showOptions')
            mainWhen.current.classList.remove('option-border')
            contentMains.current.classList.remove('option-border')
        };
    });

    return (
        <>
        <Header/>
        <div className="contact-content" ref={contactContent} 
        onClick={(e)=>{
            if(e.target === mainWhen.current || e.target === whenLabel.current){
                optionsTime.current.classList.toggle('showOptions')
                mainWhen.current.classList.toggle('option-border')
            }else{
                optionsTime.current.classList.remove('showOptions')
                mainWhen.current.classList.remove('option-border')
            }
            if(e.target === contentMains.current){
                optionsMain.current.classList.toggle('showOptions')
                contentMains.current.classList.toggle('option-border')
            }
            if(e.target === contactContent.current){
                optionsMain.current.classList.remove('showOptions')
                contentMains.current.classList.remove('option-border')
            }
        }}>
        <div className='contact-info' onClick={()=>{
            optionsMain.current.classList.remove('showOptions')
        }}>
        <Bounce>
            <p>let’s talk</p>
            <p>Ⓒ Makata Studio 2023</p>
            <div className='info-social'>
                <a href="https://twitter.com/MakataStudio"  className="icon-tw-contact2" target="blank">
                    <img src={twitter} alt="icons"className="icon-contact"/>
                    <img src={twitterHover} className="icon-hover-contact" alt="icons" />
                </a>
                <a href="https://www.instagram.com/makatastudio/" className="icon-ig-contact2" target="blank">
                    <img src={instagram} alt="icons"  className="icon-contact"  />
                    <img src={instagramHover}  className="icon-hover-contact" alt="icons" />
                </a>
            </div>
            </Bounce>
        </div>
        <form className="form-email" ref={formEmail} onSubmit={sendEmail}>
            <div className="contact-name" onClick={()=>{
                optionsMain.current.classList.remove('showOptions')
                contentMains.current.classList.remove('option-border')
            }} ref={textName}>
                <label htmlFor='name'>Your name<span ref={labelName}> *</span></label>
                <input id='name' type='text' name='name' ref={inputName} placeholder='Firstname Lastname' onChange={()=>{
                    labelName.current.classList.remove('asterisk-validation')
                    textName.current.classList.remove('validation-input')
                }}/>
            </div>
            <div className="contact-email" onClick={()=>{
                optionsMain.current.classList.remove('showOptions')
                contentMains.current.classList.remove('option-border')
            }} ref={textEmail}>
                <label htmlFor='email'>Email<span ref={labelEmail} > *</span></label>
                <input id='email' type='email' name='email' placeholder='you@mail.com' ref={inputEmail} onChange={()=>{
                    labelEmail.current.classList.remove('asterisk-validation')
                    textEmail.current.classList.remove('validation-input')
                }}/>
            </div>
            <div className="contact-interest" ref={interestMain}>
                <label htmlFor='interest' onClick={()=>{
                    optionsMain.current.classList.toggle('showOptions')
                    contentMains.current.classList.toggle('option-border')
                }}>Interest<span ref={labelInterest}> *</span></label>
                <div className="interest-options">
                    <div className='options-placeholder' ref={contentMains}>
                        <p className='options-empty' ref={optionsEmpty}>Select services you want</p>
                        <p className='checked checked-concept' ref={checkedConcept} onClick={()=>{
                            conceptArts.current.checked = false
                            checkedConcept.current.classList.remove('show-checked')
                            checked()
                        }}>Concept Arts</p>
                        <p className='checked checked-modelling' ref={checkedModelling} onClick={()=>{
                            modelling.current.checked = false
                            checkedModelling.current.classList.remove('show-checked')
                            checked()
                        }}>3D things</p>
                        <p className='checked checked-rigging' ref={checkedRigging} onClick={()=>{
                            rigging.current.checked = false
                            checkedRigging.current.classList.remove('show-checked')
                            checked()
                        }}>Design Apps</p>
                        <p className='checked checked-animations' ref={checkedAnimations} onClick={()=>{
                            animation.current.checked = false
                            checkedAnimations.current.classList.remove('show-checked')
                            checked()
                        }}>Dev Experiences</p>
                    </div>
                    <div className='options-main' ref={optionsMain} onClick={checked}>
                        <div>
                            <input id='conceptArts' type='checkbox' name='conceptArts' value='Concept Arts.' ref={conceptArts} onClick={()=>{
                                checkedConcept.current.classList.toggle('show-checked')
                                labelInterest.current.classList.remove('asterisk-validation')
                                interestMain.current.classList.remove('validation-input')
                             }}/>
                            <label htmlFor='conceptArts' ref={conceptArtsLabel}>Concept Arts</label>
                        </div>
                        <div>
                            <input id='3Dthings' type='checkbox' name='3Dthings' value='3Dthings.' ref={modelling} onClick={()=>{
                                checkedModelling.current.classList.toggle('show-checked')
                                labelInterest.current.classList.remove('asterisk-validation')
                                interestMain.current.classList.remove('validation-input')
                            }}/>
                            <label htmlFor='3Dthings' ref={modellingLabel}>3D things</label>
                        </div>
                        <div>
                            <input id='DesignApps' type='checkbox' name='rigging' value='Design Apps.' ref={rigging} onClick={()=>{
                                checkedRigging.current.classList.toggle('show-checked')
                                labelInterest.current.classList.remove('asterisk-validation')
                                interestMain.current.classList.remove('validation-input')
                            }}/>
                            <label htmlFor='DesignApps' ref={riggingLabel}>Design Apps</label>
                        </div>
                        <div>
                            <input id='DevExperiences' type='checkbox' name='DevExperiences' value='Dev Experiences.' ref={animation} onClick={()=>{
                                checkedAnimations.current.classList.toggle('show-checked')
                                labelInterest.current.classList.remove('asterisk-validation')
                                interestMain.current.classList.remove('validation-input')
                            }}/>
                            <label htmlFor='DevExperiences' ref={animationLabel}>Dev Experiences</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact-when' ref={whereMain}>
                <label htmlFor='when' ref={whenLabel}>When?<span ref={whereAsterisk}> *</span></label>
                <div className='when-content'>
                    <div className='when-head' ref={mainWhen}>
                        <p>
                            Select the time you need
                        </p>
                        <img src={arrow} ref={imgdown} alt="Arrow form"/>
                    </div>
                    <div className='when-inputs' ref={optionsTime}>
                        <div>
                            <input id='yesterday' type='checkbox' name='yesterday' value='Priority 1' ref={yesterday}/>
                            <label htmlFor='yesterday' ref={yesterdayLabel} onClick={()=>{
                                if(yesterday){
                                    yesterdayLabel.current.classList.add('label-selected')
                                    nextWeekLabel.current.classList.remove('label-selected')
                                    futureLabel.current.classList.remove('label-selected')
                                }
                                nextWeek.current.checked = false
                                future.current.checked = false
                                mainWhen.current.children[0].innerText = 'Extreme ASAP yesterday'
                                mainWhen.current.children[0].classList.add('select-when')
                                optionsTime.current.classList.remove('showOptions')
                                whereAsterisk.current.classList.remove('asterisk-validation')
                                whereMain.current.classList.remove('validation-input')
                            }}>Extreme ASAP yesterday</label>
                        </div>
                        <div>
                            <input id='week' type='checkbox' name='week' value='Priority 2' ref={nextWeek} />
                            <label htmlFor='week' ref={nextWeekLabel} onClick={()=>{
                                if(nextWeek){
                                    nextWeekLabel.current.classList.add('label-selected')
                                    yesterdayLabel.current.classList.remove('label-selected')
                                    futureLabel.current.classList.remove('label-selected')
                                }
                                yesterday.current.checked = false
                                future.current.checked = false
                                mainWhen.current.children[0].innerText = 'We need it next weeks'
                                mainWhen.current.children[0].classList.add('select-when')
                                optionsTime.current.classList.remove('showOptions')
                                whereAsterisk.current.classList.remove('asterisk-validation')
                                whereMain.current.classList.remove('validation-input')
                            }}>We need it next weeks</label>
                        </div>
                        <div>
                            <input id='future' type='checkbox' name='future' value='Priority 3' ref={future}/>
                            <label htmlFor='future' ref={futureLabel} onClick={()=>{
                                if(future){
                                    futureLabel.current.classList.add('label-selected')
                                    nextWeekLabel.current.classList.remove('label-selected')
                                    yesterdayLabel.current.classList.remove('label-selected')
                                }
                                yesterday.current.checked = false
                                nextWeek.current.checked = false
                                mainWhen.current.children[0].innerText = 'Building stuff for the future'
                                mainWhen.current.children[0].classList.add('select-when')
                                optionsTime.current.classList.remove('showOptions')
                                whereAsterisk.current.classList.remove('asterisk-validation')
                                whereMain.current.classList.remove('validation-input')
                            }}>Building stuff for the future</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact-textarea' onClick={()=>{
                optionsMain.current.classList.remove('showOptions')
                contentMains.current.classList.remove('option-border')
            }} ref={textAreaMain}>
                <label htmlFor='textarea'>Describe your idea(s)<span ref={textAreaAsterisk}> *</span></label>
                <textarea name="message" id='textarea' placeholder='Select the time you need'  minLength='20' ref={textAreaInput} onChange={()=>{
                     textAreaAsterisk.current.classList.remove('asterisk-validation')
                     textAreaMain.current.classList.remove('validation-input-textArea')
                }}></textarea>
            </div>
            <button type="submit">Send chat</button>
        </form>
        <div className='form-submit' ref={submitForm}>
            <div className='video-contact'>
                <img src={sendForm} alt="send form"/>
            </div>
            <p className='submit-text'>Your message was received. We’ll be in touch soon!</p>
            <button className='send-another' onClick={sendOtherChat}>Send another chat</button>
        </div>
      </div>
        </>
    );
  }

  export default Contact;