import Header from "../../utils/Header/Header";
function Home() {
  return (
    <div className="error">
      <Header/>
      <h2>404</h2>
    </div>
  );
}

export default Home;
