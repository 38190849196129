import Contact from './Contact'
import video from '../../../assets/videos/HONK_DISCOVERTAB_MKTWEBSITE_1920X1080.mp4'


function ContactPage() {
  const atribute = true
  return (
    <div className='contact-main'>
      <div className='contact-video'>
        <video src={video} autoPlay muted loop webkit-playsinline="true" playsInline={atribute}/>
      </div>
      <Contact/>
    </div>
  );
}

export default ContactPage;
