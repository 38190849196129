import imgConcept from "../../../assets/videos/gifs/MAKATA_WEB_SERVICES_CONCEPTS.gif";
import img3D from "../../../assets/videos/gifs/MAKATA_WEB_SERVICES_3DANIMATION.gif";
import imgDev from "../../../assets/videos/gifs/MAKATA_WEB_SERVICES_DEV.gif";
import imgDesing from "../../../assets/videos/gifs/MAKATA_WEB_SERVICES_APPDESIGN.gif";

import Contact from "../../pages/Contact/Contact";
import Slider from "../../utils/Sliders/SliderResponsive";
import SliderDesktop from "../../utils/Sliders/SliderDesktop";
import Modal from "../../utils/Modal/Modal";

import "swiper/less";
import "swiper/less/navigation";
import "swiper/less/pagination";

import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";

function About() {
  const conceptGif = useRef()
  const ModelGif = useRef()
  const devGif = useRef()
  const desingGif = useRef()
  return (
    <main className="main-about">
      <section className="about-head">
        <p className="fade-text1 fade-in">
          Directly from{" "}
          <b className="tooltip-container">
            Colombia<span className="tooltip tooltip-notColumbia">not Columbia</span>
          </b>
          , we work side-by-side with tech founders and their teams.
        </p>

        <p className="fade-text2 fade-in">
          Together, we create bold and mischievous digital{" "}
          <b className="tooltip-container tooltip-container-crem">
            products.<span className="tooltip tooltip-crem">✨ crème de la crème ✨</span>
          </b>
        </p>

        <p className="fade-text3 fade-in">
          Positive vibes are our mantra. Yubo, Bunch, or Kard{" "}
          <b className="tooltip-container tooltip-container-hope">
            won’t say<span className="tooltip tooltip-hope">hope they won’t 🥶</span>
          </b>{" "}
          otherwise ;)
        </p>
      </section>
      <section className="about-services">
        <article className="services-head">
          <Fade>
            <h3>Services</h3>
            <p>You name it! But if you'd prefer us to get specific:</p>
          </Fade>
        </article>
        <article className="services-content">
          <div className="services-titles">
            <div className="services-concept blue-wave" 
            onMouseEnter={()=>{ conceptGif.current.classList.add('show-gif') }} 
            onMouseLeave={()=>{ conceptGif.current.classList.remove('show-gif') }}>
              <Flip top>
                {" "}
                <h3 className="">Concept arts</h3>
              </Flip>
            </div>
            <div className="services-modelling blue-wave1" 
            onMouseEnter={()=>{ ModelGif.current.classList.add('show-gif') }} 
            onMouseLeave={()=>{ ModelGif.current.classList.remove('show-gif') }}>
              <Flip top>
                {" "}
                <h3>3D things</h3>
              </Flip>
            </div>
            <div className="services-rigging blue-wave2"
            onMouseEnter={()=>{ devGif.current.classList.add('show-gif') }} 
            onMouseLeave={()=>{ devGif.current.classList.remove('show-gif') }}>
              <Flip top>
                {" "}
                <h3>Design Apps</h3>
              </Flip>
            </div>
            <div className="services-animations blue-wave3"
            onMouseEnter={()=>{ desingGif.current.classList.add('show-gif') }} 
            onMouseLeave={()=>{ desingGif.current.classList.remove('show-gif') }}>
              <Flip top>
                {" "}
                <h3>Dev Experiences</h3>
              </Flip>
            </div>
          </div>
          <div className="services-gif">
            <div className="services-hover" ref={conceptGif}>
              <img className="img-proyect" src={imgConcept} alt="service-img" />
            </div>
            <div className="services-hover" ref={ModelGif}>
              <img className="img-proyect" src={img3D} alt="service-img" />
            </div>
            <div className="services-hover" ref={devGif}>
              <img className="img-proyect" src={imgDesing} alt="service-img" />
            </div>
            <div className="services-hover" ref={desingGif}>
              <img className="img-proyect" src={imgDev} alt="service-img" />
            </div>
          </div>
        </article>
        <Modal />
      </section>
      <section className="about-from">
        <article className="from-head">
          <Fade>
            <h3>Clients</h3>
            <p>
              You didn't ask, but look at the incredible things our clients have
              to say:
            </p>
          </Fade>
        </article>
        <article className="form-customers">
          <Bounce>
            <Slider />
          </Bounce>
        </article>
        <article className="form-customers-desktop">
          <Fade>
            <SliderDesktop />
          </Fade>
        </article>
      </section>
      <section className="contact">
        <Contact />
      </section>
    </main>
  );
}

export default About;
