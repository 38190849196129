import Customuse from "../../../assets/images/icons/Customuse.jpg";
import kard from "../../../assets/images/icons/Kard.jpg";
import Bunch from "../../../assets/images/icons/Bunch.jpg";
import Luni from "../../../assets/images/icons/Luniapp.jpg";
import Screen from "../../../assets/images/icons/Screenshot.jpg";
import Yubo from "../../../assets/images/icons/Yubo.jpg";
import MKT from "../../../assets/images/icons/Sun_White.png";

import { useEffect, useRef } from "react";

function SliderDesktop() {
  const img1 = useRef();
  const img2 = useRef();
  const img3 = useRef();
  const img4 = useRef();
  const img5 = useRef();
  const img6 = useRef();
  const img7 = useRef();
  const img8 = useRef();
  const img9 = useRef();
  const img10 = useRef();
  const img11 = useRef();
  const description1 = useRef();
  const description2 = useRef();
  const description3 = useRef();
  const description4 = useRef();
  const description5 = useRef();
  const description6 = useRef();
  const description7 = useRef();
  const description8 = useRef();
  const description9 = useRef();
  const description10 = useRef();
  const description11 = useRef();

  let arrayClients = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
  ];
  let index = 0;

  function closeHover() {
    for (let i = 0; i < arrayClients.length; i++) {
      arrayClients[i].current.addEventListener("click", () => {
        arrayClients[i].current.classList.add("active");
        arrayClients[i].current.setAttribute("active", "true");
        arrayClients.forEach((element, j) => {
          if (j !== i) {
            element.current.classList.remove("active");
            element.current.setAttribute("active", "false");
          }
        });
      });
    }
  }

  function getElementActive() {
    let i = 0;
    for (; i < arrayClients.length; i++) {
      let getAtribute = arrayClients[i].current.getAttribute("active");
      if (getAtribute === "true") {
        index = i;
        break;
      }
    }

    if (index === 0) {
      img2.current.classList.add("img2-bottom");
      img3.current.classList.add("img3-bottom");
      img4.current.classList.add("img4-bottom1");
      img5.current.classList.add("img5-bottom-right");
      img8.current.classList.add("img8-right");
      img9.current.classList.add("img9-bottom-right");
      description1.current.classList.add("show-description");
    } else {
      img2.current.classList.remove("img2-bottom");
      img3.current.classList.remove("img3-bottom");
      img4.current.classList.remove("img4-bottom1");
      img5.current.classList.remove("img5-bottom-right");
      img8.current.classList.remove("img8-right");
      img9.current.classList.remove("img9-bottom-right");
      description1.current.classList.remove("show-description");
    }

    if (index === 1) {
      img3.current.classList.add("img3-bottom2");
      img4.current.classList.add("img4-bottom2");
      img5.current.classList.add("img5-top-left");
      img6.current.classList.add("img6-bottom-right");
      img7.current.classList.add("img7-left2");
      img8.current.classList.add("img8-right2");
      img9.current.classList.add("img9-right");
      description2.current.classList.add("show-description");
    } else {
      img3.current.classList.remove("img3-bottom2");
      img4.current.classList.remove("img4-bottom2");
      img5.current.classList.remove("img5-top-left");
      img6.current.classList.remove("img6-bottom-right");
      img7.current.classList.remove("img7-left2");
      img8.current.classList.remove("img8-right2");
      img9.current.classList.remove("img9-right");
      description2.current.classList.remove("show-description");
    }

    if (index === 2) {
      img4.current.classList.add("img4-bottom3");
      img7.current.classList.add("img7-bottom-left");
      description3.current.classList.add("show-description");
    } else {
      img4.current.classList.remove("img4-bottom3");
      img7.current.classList.remove("img7-bottom-left");
      description3.current.classList.remove("show-description");
    }

    if (index === 3) {
      img1.current.classList.add("img1-top");
      img2.current.classList.add("img2-top");
      img3.current.classList.add("img3-top");
      img5.current.classList.add("img5-top-right");
      img7.current.classList.add("img7-left");
      img9.current.classList.add("img9-right2");
      img11.current.classList.add("img11-left");
      description4.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-top");
      img2.current.classList.remove("img2-top");
      img3.current.classList.remove("img3-top");
      img5.current.classList.remove("img5-top-right");
      img7.current.classList.remove("img7-left");
      img9.current.classList.remove("img9-right2");
      img11.current.classList.remove("img11-left");
      description4.current.classList.remove("show-description");
    }

    if (index === 4) {
      img1.current.classList.add("img1-top-left");
      img2.current.classList.add("img2-bottom-left");
      img3.current.classList.add("img3-bottom-left");
      img4.current.classList.add("img4-bottom4");
      img6.current.classList.add("img6-bottom");
      img8.current.classList.add("img8-right3");
      img9.current.classList.add("img9-right3");
      description5.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-top-left");
      img2.current.classList.remove("img2-bottom-left");
      img3.current.classList.remove("img3-bottom-left");
      img4.current.classList.remove("img4-bottom4");
      img6.current.classList.remove("img6-bottom");
      img8.current.classList.remove("img8-right3");
      img9.current.classList.remove("img9-right3");
      description5.current.classList.remove("show-description");
    }

    if (index === 5) {
      img1.current.classList.add("img1-top-left2");
      img2.current.classList.add("img2-left");
      img3.current.classList.add("img3-bottom-left2");
      img4.current.classList.add("img4-bottom");
      img5.current.classList.add("img5-top");
      img10.current.classList.add("img10-bottom-left");
      description6.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-top-left2");
      img2.current.classList.remove("img2-left");
      img3.current.classList.remove("img3-bottom-left2");
      img4.current.classList.remove("img4-bottom");
      img5.current.classList.remove("img5-top");
      img10.current.classList.remove("img10-bottom-left");
      description6.current.classList.remove("show-description");
    }

    if (index === 6) {
      img3.current.classList.add("img3-top-left");
      img2.current.classList.add("img2-top-left");
      img11.current.classList.add("img11-left2");
      description7.current.classList.add("show-description");
    } else {
      img3.current.classList.remove("img3-top-left");
      img2.current.classList.remove("img2-top-left");
      img11.current.classList.remove("img11-left2");
      description7.current.classList.remove("show-description");
    }

    if (index === 7) {
      img1.current.classList.add("img1-left");
      img2.current.classList.add("img2-bottom-left2");
      img3.current.classList.add("img3-bottom3");
      img4.current.classList.add("img4-bottom5");
      img5.current.classList.add("img5-bottom-left");
      img7.current.classList.add("img7-left3");
      img9.current.classList.add("img9-bottom");
      img10.current.classList.add("img10-bottom-left2");
      img11.current.classList.add("img11-bottom-right");
      description8.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-left");
      img2.current.classList.remove("img2-bottom-left2");
      img3.current.classList.remove("img3-bottom3");
      img4.current.classList.remove("img4-bottom5");
      img5.current.classList.remove("img5-bottom-left");
      img7.current.classList.remove("img7-left3");
      img9.current.classList.remove("img9-bottom");
      img10.current.classList.remove("img10-bottom-left2");
      img11.current.classList.remove("img11-bottom-right");
      description8.current.classList.remove("show-description");
    }

    if (index === 8) {
      img1.current.classList.add("img1-top-left3");
      img2.current.classList.add("img2-left2");
      img5.current.classList.add("img5-left2");
      img8.current.classList.add("img8-top");
      img10.current.classList.add("img10-bottom");
      description9.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-top-left3");
      img2.current.classList.remove("img2-left2");
      img5.current.classList.remove("img5-left2");
      img8.current.classList.remove("img8-top");
      img10.current.classList.remove("img10-bottom");
      description9.current.classList.remove("show-description");
    }

    if (index === 9) {
      description10.current.classList.add("show-description");
    } else {
      description10.current.classList.remove("show-description");
    }

    if (index === 10) {
      img1.current.classList.add("img1-top-left4");
      img2.current.classList.add("img2-left3");
      img3.current.classList.add("img3-left");
      img4.current.classList.add("img4-bottom6");
      img5.current.classList.add("img5-left3");
      img7.current.classList.add("img7-top-left");
      img9.current.classList.add("img9-top");
      img10.current.classList.add("img10-top");
      description11.current.classList.add("show-description");
    } else {
      img1.current.classList.remove("img1-top-left4");
      img2.current.classList.remove("img2-left3");
      img3.current.classList.remove("img3-left");
      img4.current.classList.remove("img4-bottom6");
      img5.current.classList.remove("img5-left3");
      img7.current.classList.remove("img7-top-left");
      img9.current.classList.remove("img9-top");
      img10.current.classList.remove("img10-top");
      description11.current.classList.remove("show-description");
    }
  }

  useEffect(() => {
    closeHover();
  });

  return (
    <article
      className="form-customers-desktop"
      onClick={() => {
        getElementActive();
      }}
    >
      <ul className="customers-main">
        <li className="customers-content-desktop">
          <div className="content-img-desktop img1" ref={img1}>
            <img src={Customuse} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description1}>
            <h3>Customuse</h3>
            <a
              href="https://twitter.com/customuse3d?lang=es"
              target="_blank"
              rel="noreferrer"
            >
              @customuse3D
            </a>
            <p>
              Working with makata team brings me a ton of joy<br></br>
              <br></br>
              <b>Arthur Safaryan</b>
              <br></br>
              <i>CEO of Customuse</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img2" ref={img2}>
            <img src={kard} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description2}>
            <h3>Kard</h3>
            <a
              href="https://twitter.com/Kard_App"
              target="_blank"
              rel="noreferrer"
            >
              @Kard_App
            </a>
            <p>
              We’ve partnered with Makata pretty much since day 1, they’ve
              always played a tremendous role in Kard’s success.<br></br>
              <br></br>
              <b>Scott Gordon</b>
              <br></br>
              <i>CEO of KARD</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img3" ref={img3}>
            <img src={Bunch} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description3}>
            <h3>Bunch</h3>
            <a
              href="https://twitter.com/bunchverse"
              target="_blank"
              rel="noreferrer"
            >
              @bunchverse
            </a>
            <p>
              Great design partners! We’ve been working together for many years
              now!<br></br>
              <br></br>
              <b>Selcuk Atli</b>
              <br></br>
              <i>CEO of Bunch.live</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img4" ref={img4}>
            <img src={Luni} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description4}>
            <h3>Luni</h3>
            <a
              href="https://twitter.com/Luni_app"
              target="_blank"
              rel="noreferrer"
            >
              @Luni_app
            </a>
            <p>
              Makata team is fast, creative, and always open to new challenges!
              Happy to have them by our side at Luni!<br></br>
              <br></br>
              <b>Adrien Miniatti</b>
              <br></br>
              <i>CEO of Luni</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop ">
          <div className="content-img-desktop img5" ref={img5}>
            <img src={Screen} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description5}>
            <h3>Screenshot Labs</h3>
            <a
              href="https://mobile.twitter.com/screenshotlabs"
              target="_blank"
              rel="noreferrer"
            >
              @ScreenshotLabs
            </a>
            <p>
              Speed of execution, attentive to our feedbacks, undeniable quality
              of the deliverables<br></br>
              <br></br>
              <b>Team Everai</b>
              <br></br>
              <i>Screenshot.co</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img6" ref={img6}>
            <img src={MKT} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description6}>
            <h3>Yubo</h3>
            <a
              href="https://twitter.com/yubo_app"
              target="_blank"
              rel="noreferrer"
            >
              @yubo_app
            </a>
            <p>
              Makata has been a long time partner of Yubo! We did many great
              project together as feature videos, 3D and even lenses for an
              in-app use. Always happy to ask for their help!<br></br>
              <br></br>
              <b>Jeremie Aouate</b>
              <br></br>
              <i>Co-Founder & CPO at Yubo</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img7" ref={img7}>
            <img src={Yubo} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description7}>
            <h3>Yubo</h3>
            <a
              href="https://twitter.com/yubo_app"
              target="_blank"
              rel="noreferrer"
            >
              @yubo_app
            </a>
            <p>
              Makata has been a long time partner of Yubo! We did many great
              project together as feature videos, 3D and even lenses for an
              in-app use. Always happy to ask for their help!<br></br>
              <br></br>
              <b>Jeremie Aouate</b>
              <br></br>
              <i>Co-Founder & CPO at Yubo</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img8" ref={img8}>
            <img src={Bunch} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description8}>
            <h3>Bunch</h3>
            <a
              href="https://twitter.com/bunchverse"
              target="_blank"
              rel="noreferrer"
            >
              @bunchverse
            </a>
            <p>
              Great design partners! We’ve been working together for many years
              now!<br></br>
              <br></br>
              <b>Selcuk Atli</b>
              <br></br>
              <i>CEO of Bunch.live</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img9" ref={img9}>
            <img src={Luni} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description9}>
            <h3>Luni</h3>
            <a
              href="https://twitter.com/Luni_app"
              target="_blank"
              rel="noreferrer"
            >
              @Luni_app
            </a>
            <p>
              Makata team is fast, creative, and always open to new challenges!
              Happy to have them by our side at Luni!<br></br>
              <br></br>
              <b>Adrien Miniatti</b>
              <br></br>
              <i>CEO of Luni</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div
            className="content-img-desktop img10 active"
            ref={img10}
            active="true"
          >
            <img src={Customuse} alt="snapchat-img-desktop" />
          </div>
          <div
            className="snapchat-content-desktop show-description"
            ref={description10}
          >
            <h3>Customuse</h3>
            <a
              href="https://twitter.com/customuse3d?lang=es"
              target="_blank"
              rel="noreferrer"
            >
              @customuse3D
            </a>
            <p>
              Working with makata team brings me a ton of joy<br></br>
              <br></br>
              <b>Arthur Safaryan</b>
              <br></br>
              <i>CEO of Customuse</i>
            </p>
          </div>
        </li>
        <li className="customers-content-desktop">
          <div className="content-img-desktop img11" ref={img11}>
            <img src={Yubo} alt="snapchat-img-desktop" />
          </div>
          <div className="snapchat-content-desktop" ref={description11}>
            <h3>Yubo</h3>
            <a
              href="https://twitter.com/yubo_app"
              target="_blank"
              rel="noreferrer"
            >
              @yubo_app
            </a>
            <p>
              Makata has been a long time partner of Yubo! We did many great
              project together as feature videos, 3D and even lenses for an
              in-app use. Always happy to ask for their help!<br></br>
              <br></br>
              <b>Jeremie Aouate</b>
              <br></br>
              <i>Co-Founder & CPO at Yubo</i>
            </p>
          </div>
        </li>
      </ul>
    </article>
  );
}

export default SliderDesktop;
