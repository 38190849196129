import arrow from "../../../assets/images/icons/arrow.svg";
import arrowR from "../../../assets/images/icons/arrowR.svg";
import twitter from "../../../assets/images/icons/twitter.svg";
import instagram from "../../../assets/images/icons/Instagram.svg";
import twitterHover from "../../../assets/images/icons/tw_hover.png";
import instagramHover from "../../../assets/images/icons/ig_hover.png";
import close from "../../../assets/images/icons/close.svg";

//Images Videos
import waldo from "../../../assets/images/iconsHome/waldo_Icon.png";
import honk from "../../../assets/images/iconsHome/omada_Icon.png";
import kard from "../../../assets/images/iconsHome/kard_Icon.png";
import backmarket from "../../../assets/images/iconsHome/backmarket_Icon.png";
import honkH from "../../../assets/images/iconsHome/cosmos_Icon.png";
import cosmos from "../../../assets/images/iconsHome/honk_Icon.png";
import omadaAFootball from "../../../assets/images/iconsHome/omada_Icon.png";
import omadaSoccer from "../../../assets/images/iconsHome/honk_Icon.png";
import customuse from "../../../assets/images/iconsHome/custo_Icon.png";
import family from "../../../assets/images/iconsHome/family_icon.png";
import JBalvin from "../../../assets/images/iconsHome/MAKATA_JBALVIN_ICON.png";

import videoDescription from '../../../assets/json/videos.json'

import React, { useRef, useEffect } from "react";

function Footer(props) {
  const notificationText = useRef();
  const footerNotification = useRef();
  const notificationClose = useRef();
  const locationDescription = useRef();
  const subTitleVideo = useRef();
  const titleVideo = useRef();
  const BlockVideo = useRef();
  const imgDesc = useRef();
  const vidDesc = useRef();
  const notText = useRef();
  const arrowLeft = useRef();
  const arrowRight = useRef();

  let arrayImages = [
    waldo,
    omadaSoccer,
    kard,
    backmarket,
    honkH,
    cosmos,
    omadaAFootball,
    honk,
    customuse,
    family,
    JBalvin,
  ];
  let totalString = props.description.video[1].length;
  let text1 = props.description.video[1].slice(0, 65);
  let text2 = props.description.video[1].slice(65, totalString);
  let videoProps = props.description.video[0]

  function hiddenNotification() {
    let notificationTextActive =
      notificationText.current.getAttribute("active");
    footerNotification.current.classList.toggle("hidden-background");
    footerNotification.current.classList.toggle("show-background");
    if (notificationTextActive) {
      notificationText.current.setAttribute("active", "false");
      locationDescription.current.classList.remove("rotate-plus");
    }
    if (notificationTextActive === "false") {
      notificationText.current.setAttribute("active", "true");
      if (
        !navigator.userAgent.match(/Android/i) ||
        !navigator.userAgent.match(/webOS/i) ||
        !navigator.userAgent.match(/iPhone/i) ||
        !navigator.userAgent.match(/iPad/i) ||
        !navigator.userAgent.match(/iPod/i) ||
        !navigator.userAgent.match(/BlackBerry/i) ||
        !navigator.userAgent.match(/Windows Phone/i)
      ) {
        locationDescription.current.classList.add("rotate-plus");
      }
    }
  }
  function showMore() {
    let moreText = document.querySelector(".more-text");
    let moreTextBtn = document.querySelector(".more-text-btn");
    moreText.classList.toggle("more-text-show");

    if (moreTextBtn.innerText === "See more") {
      moreTextBtn.innerText = "Hide";
    } else {
      moreTextBtn.innerText = "See more";
    }
  }
  function refreshAnimation(){
    let location = window.location.pathname
    if(location ===  '/'){
      titleVideo.current?.classList.remove("fade1");
      subTitleVideo.current?.classList.remove("fade1");
      BlockVideo.current?.classList.remove("block");
      imgDesc.current?.classList.remove("fade1");
      vidDesc.current?.classList.remove("slideInUp");
      notText.current?.classList.remove("fade2");
    }
  }
  function addAnimation(){
    titleVideo.current.classList.add("fade1");
    subTitleVideo.current.classList.add("fade1");
    BlockVideo.current.classList.add("block");
    imgDesc.current.classList.add("fade1");
    vidDesc.current.classList.add("slideInUp");
    notText.current.classList.add("fade2");
  }
  function validationsDeviceHover(){
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      locationDescription.current.classList.remove("hover-location");
      locationDescription.current.classList.remove("rotate-plus");
      arrowLeft.current.classList.remove('location-arrows-hover')
      arrowRight.current.classList.remove('location-arrows-hover')
    } 
  }

  setTimeout(() => {
    refreshAnimation()
  }, 2000);

  useEffect(() => {
    addAnimation()
    validationsDeviceHover()
  }, [videoProps]);

  
  return (
    <footer className="footer-main">
      <div
        className="footer-notification show-background"
        ref={footerNotification}
      >
        <div ref={notText}>
          <div
            className="notification-close"
            ref={notificationClose}
            onClick={hiddenNotification}
          >
            <img src={close} alt="arrow" />
          </div>
          <div
            className="notification-text show-notification"
            ref={notificationText}
            active="true"
          >
            <h3>{props.description.video[0]}</h3>
            <h4>{props.description.video[5]}</h4>
            <p>
              {text1}
              <span className="notification-dots">...</span>{" "}
              <span className="more-text">{text2}</span>
              <button onClick={showMore} className="more-text-btn">
                See more
              </button>
            </p>
          </div>
        </div>
      </div>
      <section className="footer-location">
        <article className="location-content">
          <div className="location-arrows location-arrows-hover footer-arrow-left" ref={arrowLeft}>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="location-description rotate-plus hover-location"
            ref={locationDescription}
            onClick={hiddenNotification}
          >
            <span className="block" ref={BlockVideo}></span>
            <div className="description-icon">
              <img
                src={arrayImages[props.description.video[4] - 1]}
                alt="arrow"
                ref={imgDesc}
              />
            </div>
            <div className="description-content">
              <h3 className="description-content-text" ref={titleVideo}>
                {props.description.video[0]}
              </h3>
              <h4 className="slideInDown" ref={subTitleVideo}>
                {props.description.video[5]}
              </h4>
            </div>
          </div>
          <div className="location-title">
            <p className="title-video" ref={vidDesc}>
              {props.description.video[2]}
            </p>
          </div>
          <div className="location-arrows location-arrows-hover footer-arrow-right" ref={arrowRight}>
            <img src={arrowR} alt="arrow" />
          </div>
        </article>
        <article className="location-copy">
          <div className="copy-content">
            <p className="content-textcopy">Ⓒ Makata Studio 2023</p>
            <a
              href="https://twitter.com/MakataStudio"
              target="blank"
              className="icon-tw"
            >
              <img src={twitter} alt="icons" className="icon" />
              <img src={twitterHover} className="icon-hover" alt="icons" />
            </a>
            <a
              href="https://www.instagram.com/makatastudio/"
              target="blank"
              className="icon-ig"
            >
              <img src={instagram} alt="icons" className="icon" />
              <img src={instagramHover} className="icon-hover" alt="icons" />
            </a>
          </div>
        </article>
      </section>
    </footer>
  );
}

export default Footer;
