import { Link, useLocation } from "react-router-dom";
import mmakata from "../../../assets/images/icons/mmakata.svg";
import mmakataBlack from "../../../assets/images/icons/mmakataBlack.svg";
import fullMakata from "../../../assets/images/icons/makataLogoFull.svg";
import fullMakataBlack from "../../../assets/images/icons/makataLogoFullBlack.svg";
import { useEffect, useRef } from "react";
import lottie from "lottie-web";
import makataAnimation from "../../../assets/lotties/makataAnimation.json";

import videoMakata from "../../../assets/videos/MAKATA_ICON_M_FULL SIZE.mp4"
import twitter from "../../../assets/images/icons/twitter.svg";
import instagram from "../../../assets/images/icons/Instagram.svg";
import twitterHover from "../../../assets/images/icons/tw_hover.png";
import instagramHover from "../../../assets/images/icons/ig_hover.png";


function Header(props) {
  const lottieAnimation = useRef()
  const videoMakataHover = useRef()
  const headerBurger = useRef()
  const atribute = true;
  let location = useLocation()
  const menu = useRef()
  const burgerMenu = useRef()
  const makataM = useRef()
  const headerNav = useRef()
  const headerMain = useRef()
  const makataComplete = useRef()
  const underlineAbout = useRef()
  const underlineContact = useRef()

  useEffect(()=>{
    if(location.pathname !== '/'){
      menu.current.classList.add('menu-pages')
    }else{
      menu.current.classList.remove('menu-pages')
      makataComplete.current.classList.remove('scroll-makatafull')
      makataM.current.classList.remove('scroll-makata')
    }

    if (location.pathname === '/about') {
      underlineAbout.current.classList.add('underline-about')
      underlineAbout.current.classList.add('underline-about-header')
      // window.onmousemove = (e)=>{
      //   if(e.target === makataM.current || e.target === videoMakataHover.current){
      //     videoMakataHover.current.classList.add('makata-video-show')
      //   }else{
      //     videoMakataHover.current.classList.remove('makata-video-show')
      //   }
      // }
    }else{
      makataComplete.current.classList.remove('scroll-makatafull')
      makataM.current.classList.remove('scroll-makata')
      underlineAbout.current.classList.remove('underline-about')
      underlineAbout.current.classList.remove('underline-about-header')
    }

    if (location.pathname === '/contact') {
      underlineContact.current.classList.add('underline-contact')
      underlineContact.current.classList.add('underline-contact-header')
    }else{
      underlineContact.current.classList.remove('underline-contact')
      underlineContact.current.classList.remove('underline-contact-header')
    }

    window.onscroll = ()=>{
      let scrollY = window.scrollY;
      if (location.pathname === '/about') {
        headerNav.current.classList.add('menu-scroll')
        makataComplete.current.classList.add('scroll-makatafull')
        lottieAnimation.current.classList.add('hidden-lottie')
        makataM.current.classList.add('scroll-makata')
        headerMain.current.classList.add('header-static')
        if (scrollY >= 2150) {
          headerMain.current.classList.add('hidden-header')
        }else{
          headerMain.current.classList.remove('hidden-header')
          makataM.current.classList.remove('hiden-m')
          videoMakataHover.current.classList.remove('makata-video-show')
        }
        if (scrollY <= 800) {
          makataComplete.current.classList.remove('scroll-makatafull')
          makataM.current.classList.remove('scroll-makata')
          headerNav.current.classList.remove('menu-scroll')
        }
      }else{
        makataComplete.current.classList.remove('scroll-makatafull')
        makataM.current.classList.remove('scroll-makata')
        headerNav.current.classList.remove('menu-scroll')
      }

      if (location.pathname === '/contact') {
        if (scrollY >= 80) {
          headerMain.current.classList.add('hidden-header')
        lottieAnimation.current.classList.add('hidden-lottie')

        }else{
          headerMain.current.classList.remove('hidden-header')
        }
      }
    }
   
  },[location.pathname])
  
  useEffect(()=>{
    if (location.pathname === '/') {
      if(props.textVideo.video[6] === 'Black'){
        makataComplete.current.setAttribute('src', fullMakataBlack)
        makataM.current.setAttribute('src', mmakataBlack)
        underlineAbout.current.classList.add('black-list')
        underlineContact.current.classList.add('black-list')
        headerBurger.current.classList.add('navBurger-Black')
      }else{
        makataComplete.current.setAttribute('src', fullMakata)
        makataM.current.setAttribute('src', mmakata)
        underlineAbout.current.classList.remove('black-list')
        underlineContact.current.classList.remove('black-list')
        headerBurger.current.classList.remove('navBurger-Black')
      };
    }
  })

  function closeHeader(){
    burgerMenu.current.checked = false
  }

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieAnimation.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: makataAnimation,
    });

    return () => {
      lottie.destroy();
    };
  }, []);
  return (
    <header className="header-main" ref={headerMain}>
      <div className="header-makata">
        <Link to="/" onClick={closeHeader}>
          <img className="makata-m" ref={makataM} src={mmakata} alt="makata" onMouseEnter={()=>{
            // videoMakataHover.current.classList.add('makata-video-show')
          }}/>
          <div className="lottie-main hidden-lottie lottie-makata" ref={lottieAnimation} onMouseLeave={()=>{
            lottieAnimation.current.classList.add('hidden-lottie')
            makataComplete.current.classList.remove('scroll-makatafull')
            lottie.stop()
          }}>
          </div>
          <img className="makata-full" ref={makataComplete} src={fullMakata} alt="makata" onMouseEnter={()=>{
            lottieAnimation.current.classList.remove('hidden-lottie')
            makataComplete.current.classList.add('scroll-makatafull')
            lottie.play()
          }}/>
          <video className='makata-video' ref={videoMakataHover} src={videoMakata} autoPlay muted loop webkit-playsinline="true" playsInline={atribute} onMouseLeave={()=>{
            videoMakataHover.current.classList.remove('makata-video-show')
          }}/>
        </Link>
      </div>
      <nav className="header-nav" ref={headerNav}>
        <input className="menu-btn" type="checkbox" id="menu-btn" ref={burgerMenu} onChange={()=>{
          if (burgerMenu.current.checked) {
            makataComplete.current.setAttribute('src', fullMakata)
            makataM.current.setAttribute('src', mmakata)
            underlineAbout.current.classList.remove('black-list')
            underlineContact.current.classList.remove('black-list')
            headerBurger.current.classList.remove('navBurger-Black')
          }else{
            if(props.textVideo.video[5] === 'Black'){
              makataComplete.current.setAttribute('src', fullMakataBlack)
              makataM.current.setAttribute('src', mmakataBlack)
              underlineAbout.current.classList.add('black-list')
              underlineContact.current.classList.add('black-list')
              headerBurger.current.classList.add('navBurger-Black')
            }
          }
        }}/>
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon" ref={headerBurger}></span>
        </label>
        <ul className="menu" ref={menu}>
          <li ref={underlineAbout} className="about-hover">
            <Link to="/about" onClick={closeHeader}>About </Link>
          </li>
          <li ref={underlineContact} className="contact-hover">
            <Link to="/contact" onClick={closeHeader}>Contact</Link>
          </li>
          <li className="footer-header">
            <p className="content-textcopy-header">Ⓒ Makata Studio 2023</p>
            <div className="icons-social-header">
              <a href="https://twitter.com/MakataStudio" target="blank" className='incons-twitter' >
                <img src={twitter} alt="icons" className="icon-twitter-header"/>
                <img src={twitterHover} className="icon-hidden icon-hidden-twitter" alt="icons"/>
              </a>
              <a href="https://www.instagram.com/makatastudio/" target="blank" className='incons-instagram'>
                <img src={instagram} alt="icons"/>
                <img src={instagramHover} className="icon-hidden icon-hidden-instagram" alt="icons" />
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
