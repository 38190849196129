import Footer from '../../utils/Footer/Footer';
import Header from '../../utils/Header/Header'
import videoDescription from '../../../assets/json/videos.json'
import videoLoader from '../../../assets/videos/MAKATA_ICON_M_FULL SIZE.mp4'
import video1Phone from '../../../assets/videos/WALDO_MKTWEBSITE_1080X1920.mp4';
import video2Phone from '../../../assets/videos/OMADA_SOCCER_MKTWEBSITE_1080X1920.mp4';
import video3Phone from '../../../assets/videos/KARD_FACES_MKTWEBSITE_1080X1920.mp4';
import video4Phone from '../../../assets/videos/BACKMARKET_MKTWEBSITE_1080X1920.mp4';
import video5Phone from '../../../assets/videos/HONK_HALLOWEEN_1080X1920.mp4';
import video6Phone from '../../../assets/videos/COSMOS_LANDINGPAGE_1080X1920.mp4';
import video7Phone from '../../../assets/videos/OMADA_NFL_MKTWEBSITE_1080X1920.mp4';
import video8Phone from '../../../assets/videos/HONK_DISCOVERTAB_MKTWEBSITE_1080X1920.mp4';
import video9Phone from '../../../assets/videos/CUSTOMUSE_MKTWEBSITE_1080X1920.mp4';
import video10Phone from '../../../assets/videos/FAMILY_OSE_1080X1920.mp4';
import video11Phone from '../../../assets/videos/MAKATA_JBALVIN_ALL_1080x1920.mp4';

import video1Desktop from '../../../assets/videos/WALDO_MKTWEBSITE_1920X1080.mp4';
import video2Desktop from '../../../assets/videos/OMADA_SOCCER_MKTWEBSITE_1920X1080.mp4';
import video3Desktop from '../../../assets/videos/KARD_FACES_MKTWEBSITE_1920X1080.mp4';
import video4Desktop from '../../../assets/videos/BACKMARKET_MKTWEBSITE_1920X1080.mp4';
import video5Desktop from '../../../assets/videos/HONK_HALLOWEEN_1920X1080.mp4';
import video6Desktop from '../../../assets/videos/COSMOS_LANDINGPAGE_1920X1080.mp4';
import video7Desktop from '../../../assets/videos/OMADA_NFL_MKTWEBSITE_1920X1080.mp4';
import video8Desktop from '../../../assets/videos/HONK_DISCOVERTAB_MKTWEBSITE_1920X1080.mp4';
import video9Desktop from '../../../assets/videos/CUSTOMUSE_MKTWEBSITE_1920X1080.mp4';
import video10Desktop from '../../../assets/videos/FAMILY_OSE_1920X1080.mp4';
import video11Desktop from '../../../assets/videos/MAKATA_JBALVIN_ALL_1920x1080_SOUND.mp4';

import { useEffect, useRef, useState } from 'react';

function Home() {
  const videoDesktopReference = useRef()
  const videoPhoneReference = useRef()
  const atribute = true;
  const loader = useRef()

  let arrayVideosP = [
    video1Phone,
    video2Phone,
    video3Phone,
    video4Phone,
    video5Phone,
    video6Phone,
    video7Phone,
    video8Phone,
    video9Phone,
    video10Phone,
    video11Phone,
  ]

  let arrayVideosD = [
    video1Desktop,
    video2Desktop,
    video3Desktop,
    video4Desktop,
    video5Desktop,
    video6Desktop,
    video7Desktop,
    video8Desktop,
    video9Desktop,
    video10Desktop,
    video11Desktop,
  ]

  let totalArrayD = (arrayVideosD.length) - 1
  let index = Math.floor(Math.random() * arrayVideosD.length)
  let [videoPhone, setVideoPhone] = useState(arrayVideosP[index]);
  let [video, setVideo] = useState(arrayVideosD[index]);
  let [videoContent, setVideoContent] = useState(videoDescription[index]);

  function changeVideo(arrowRight, arrowLeft) {
    arrowRight.addEventListener('click', () => {
      loader.current.classList.add('show-loader')
      loader.current.classList.remove('show-loader')
      if(index >= totalArrayD){
        index = 0
      }else{
        index += 1; 
      }
      setVideoPhone(arrayVideosP[index]);
      setVideo(arrayVideosD[index]);
      setVideoContent(videoDescription[index])
      videoPhoneReference.current.classList.add('change-video')
      videoDesktopReference.current.classList.add('change-video')
      setTimeout(()=>{
        videoPhoneReference.current.classList.remove('change-video')
        videoDesktopReference.current.classList.remove('change-video')
      },400)
    });
    arrowLeft.addEventListener('click', () => {
      //Desktop
      if(index > totalArrayD){
        index = 0
      }else{
        if (index === 0) {
          index = totalArrayD
        }else{
          index += -1
        }
        
      }
      setVideoPhone(arrayVideosP[index]);
      setVideo(arrayVideosD[index]);
      setVideoContent(videoDescription[index])
      videoPhoneReference.current.classList.add('change-video')
      videoDesktopReference.current.classList.add('change-video')
      setTimeout(()=>{
        videoPhoneReference.current.classList.remove('change-video')
        videoDesktopReference.current.classList.remove('change-video')
      })
    });
  }

  useEffect(() => {
    let arrowRight = document.querySelector('.footer-arrow-right');
    let arrowLeft = document.querySelector('.footer-arrow-left');
    changeVideo(arrowRight, arrowLeft);
    // eslint-disable-next-line
  }, []);


  return (
    <main className='home-main'>
      <Header textVideo={videoContent}/>
      <video className='video-phone' src={videoPhone} autoPlay muted loop webkit-playsinline="true" playsInline={atribute} ref={videoPhoneReference}/>
      <video className='video-desktop' src={video} autoPlay muted loop webkit-playsinline="true" playsInline={atribute} ref={videoDesktopReference}/>
      <div className='loader-home' ref={loader}>
        <video className='video-loader' src={videoLoader} autoPlay muted loop webkit-playsinline="true" playsInline={atribute}/>
        <div className='text-loader'>
          <h2>loading</h2>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      </div>
      <Footer description={videoContent}/>
    </main>
  );
}

export default Home;
